import { hot } from 'react-hot-loader/root'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo-hooks'
import Helmet from 'react-helmet'

import { globalHistory } from '@reach/router'
import { globalViewerId } from 'src/lib/globalViewerId'
import {
  recordAnalyticsEvent,
  AnalyticsEvents,
  AnalyticsEventTypes,
} from 'src/lib/AnalyticsEvents'
import * as Sentry from '@sentry/react'
import Header from './components/Header'
import { Flex } from './components/Box'
import ThemeProvider from './components/ThemeProvider'
import { PageBackground, BackgroundType } from './components/PageBackground'
import AppRouter from './components/AppRouter'
import strings from './strings'
import Footer from './components/Footer'
import { MAX_CONTENT_WIDTH, MIN_BODY_HEIGHT } from './constants'
import { initVerticalHeight } from './styles/verticalHeight'
import { initAnalytics } from './lib/analytics'
import { PageErrorProvider, PageError } from './components/errors'
import { LanguageContextProvider } from './components/Language'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL + '/graphql',
  credentials: 'include',
})

initVerticalHeight()
initAnalytics()

//set explicitly when the app first loads
sessionStorage.setItem('current-location', window.location.href)
sessionStorage.setItem('initial-load-done', 'true')

globalHistory.listen(({ location }) => {
  const previousLocation = sessionStorage.getItem('current-location')
  sessionStorage.setItem('current-location', location.href)

  const isSignIn = sessionStorage.getItem('sign-in') ? true : false

  if (isSignIn) {
    sessionStorage.removeItem('sign-in')
  }

  //globalViewerId.id is set in src/components/Header/index.tsx
  if (isSignIn && globalViewerId.id !== 0) {
    recordAnalyticsEvent({
      userId: globalViewerId.id,
      event: AnalyticsEvents.SignIn,
      url: window.location.href,
      type: AnalyticsEventTypes.Auth,
      title: null,
      mediaSource: null,
      objectId: null,
      mediaSourceUrl: null,
      referrer: previousLocation,
      trackStartTime: null,
      timeZoneOffset: new Date().getTimezoneOffset(),
    })
  }

  //globalViewerId.id is set in src/components/Header/index.tsx
  if (globalViewerId.id !== 0) {
    recordAnalyticsEvent({
      userId: globalViewerId.id,
      event: AnalyticsEvents.PageView,
      url: window.location.href,
      type: AnalyticsEventTypes.Page,
      title: null,
      mediaSource: null,
      objectId: null,
      mediaSourceUrl: null,
      referrer: previousLocation,
      trackStartTime: null,
      timeZoneOffset: new Date().getTimezoneOffset(),
    })

    Sentry.setUser({ id: globalViewerId.id.toString() })
  }
})

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <LanguageContextProvider>
          <PageErrorProvider>
            <Helmet
              titleTemplate={strings.pageTitles.template}
              defaultTitle={strings.pageTitles.defaultTitle}
            />
            <PageBackground type={BackgroundType.None} />

            <Header />
            <PageError />

            <Flex
              as="main"
              minHeight={MIN_BODY_HEIGHT}
              justifyContent="center"
              px={4}
              py={[6, 7]}
            >
              <Flex
                flexDirection="column"
                maxWidth={MAX_CONTENT_WIDTH}
                width="100vw"
              >
                <AppRouter />
              </Flex>
            </Flex>
            <Footer />
          </PageErrorProvider>
        </LanguageContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}
export default hot(App)
