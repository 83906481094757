import { useState, useEffect, useContext } from 'react'
import { Entry } from 'contentful'
import {
  getContentfulClient,
  getContentfulPreviewClient,
} from 'src/contentful/contentfulClient'
import { LanguageContext } from 'src/components/Language'

const entryCache: Record<string, Entry<any>> = {}

export const useContentfulEntry = <TModel extends {}>(
  entryId: string,
  preview: boolean = false
) => {
  const existingEntry = entryCache[entryId]
  const [loading, setLoading] = useState(!existingEntry)
  const [error, setError] = useState<any>(null)
  const [entry, setEntry] = useState<Entry<TModel> | null>(existingEntry)

  const languageContext = useContext(LanguageContext)
  let language = languageContext.currentUILanguage
    ? languageContext.currentUILanguage
    : 'en-US'
  language = language === 'en' ? 'en-US' : language

  useEffect(() => {
    const client =
      preview && process.env.REACT_APP_CONTENTFUL_ENABLE_PREVIEW === 'true'
        ? getContentfulPreviewClient()
        : getContentfulClient()

    client
      .getEntry<TModel>(entryId, { locale: language })
      .then(entry => {
        entryCache[entryId] = entry
        setEntry(entry)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [entryId, preview])

  return { fields: entry ? entry.fields : null, loading, error }
}

export default useContentfulEntry
