import React, { useState } from 'react'

type Languages = 'en' | 'es'

interface LanguageContextType {
  currentUILanguage: Languages
  updateUILanguage: (language: Languages) => void
}

export const LanguageContext = React.createContext<LanguageContextType>({
  currentUILanguage: 'en',
  updateUILanguage: () => {},
})

export const LanguageContextProvider: React.FunctionComponent = ({
  children,
}) => {
  if (!localStorage.getItem('uiLanguage')) {
    localStorage.setItem('uiLanguage', 'en')
  }

  const [uiLanguage, setUILanguage] = useState<Languages>(localStorage.getItem(
    'uiLanguage'
  ) as Languages | 'en')

  const updateUILanguage = (language: Languages) => {
    setUILanguage(language)
    localStorage.setItem('uiLanguage', language)
  }

  return (
    <LanguageContext.Provider
      value={{ currentUILanguage: uiLanguage, updateUILanguage }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
